<template>
  <div class="status-spinner d-flex justify-content-center align-items-center">
    <div>
      <div v-for="item in spinnerCount" :class="spinnerClasses" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { VariantOption } from "~/types/types";

interface Props {
  variant?: VariantOption;
  spinnerCount?: number;
  spinnerStyle?: "grow" | "border";
  spinnerSize?: "sm" | "md";
}

const props = withDefaults(defineProps<Props>(), {
  variant: "secondary",
  spinnerCount: 3,
  spinnerStyle: "grow",
  spinnerSize: "md",
});

const spinnerClasses = computed(() => {
  let classes = `text-${props.variant} `;
  const spinnerSizeClass = props.spinnerSize == "sm" ? "-sm" : "";
  props.spinnerStyle == "grow"
    ? (classes += `spinner-grow${spinnerSizeClass} `)
    : (classes += `spinner-border${spinnerSizeClass} `);
  return classes;
});
</script>
